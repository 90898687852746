import { useContext } from "react";
import { AppContext } from "./AppContext";

const useIntervalOverview = () => {
  const [state, setState] = useContext(AppContext);

  const getQueue = () => state.intervalOverviewQueue;

  const addToQueue = interval => {
    setState(state => ({
      ...state,
      intervalOverviewQueue: [...state.intervalOverviewQueue, interval]
    }));
  };

  const deleteFromQueue = id => {
    const newQueue = state.intervalOverviewQueue.filter(
      interval => interval.id !== id
    );

    setState(state => ({ ...state, intervalOverviewQueue: newQueue }));
  };

  return {
    getQueue,
    addToQueue,
    deleteFromQueue
  };
};

export default useIntervalOverview;
