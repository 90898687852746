import React, { useState } from "react";
import gql from "graphql-tag";
import EditBucket from "../BucketForm/EditBucket";
import AuthQuery from "../Apollo/AuthQuery";
import BucketHistory from "./BucketHistory";

const TIME_BUCKET = gql`
  query TimeBucket($id: String!) {
    timeBucket(id: $id) {
      id
      name
      description
    }
  }
`;

const BucketView = ({ match }) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <AuthQuery query={TIME_BUCKET} variables={{ id: match.params.bucketId }}>
      {({ data, loading, error }) => {
        if (loading) return <div>loading</div>;

        if (error || !data || !data.timeBucket || !data.timeBucket.name)
          return <div>error</div>;

        const { name, description, id } = data.timeBucket;

        return !editMode ? (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <div style={{ marginTop: 100, fontSize: 30 }}>{name}</div>
              <div style={{ marginTop: 20, fontSize: 20 }}>{description}</div>

              <div style={{ margin: "20px 0 100px 0" }}>
                <button onClick={() => setEditMode(true)}>edit</button>
                <button>delete</button>
              </div>
            </div>
            <BucketHistory bucketId={id} />
          </>
        ) : (
          <>
            <EditBucket
              name={name}
              description={description}
              id={id}
              onSubmit={() => setEditMode(false)}
            />
            <div style={{ width: "100%", textAlign: "center" }}>
              <button onClick={() => setEditMode(false)}>back</button>
            </div>
          </>
        );
      }}
    </AuthQuery>
  );
};

export default BucketView;
