export const capitalizeFirstLetter = str => {
  const lastLetters = str.slice(1);
  const capFirstLetter = str[0].toUpperCase();
  return `${capFirstLetter}${lastLetters}`;
};

export const msToTime = duration => {
  const milliseconds = parseInt((duration % 1000) / 100);
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  return { milliseconds, seconds, minutes, hours };
};

export const trimMsToSecond = msVal => {
  const extraMs = msVal % 1000;
  return msVal - extraMs;
};

export const countDigits = number => {
  return number.toString().length;
};

export const formatToCurrency = number =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
    number
  );

const formatMonth = num => {
  switch (num) {
    case 0:
      return "January";

    case 1:
      return "February";

    case 2:
      return "March";

    case 3:
      return "April";

    case 4:
      return "May";

    case 5:
      return "June";

    case 6:
      return "July";

    case 7:
      return "August";

    case 8:
      return "September";

    case 9:
      return "October";

    case 10:
      return "November";

    case 11:
      return "December";

    default:
      break;
  }
};

const formatHour = hour => {
  const meridian = hour < 12 ? "a.m." : "p.m.";

  const twelveHourTime = hour % 12;

  return { hour: twelveHourTime, meridian };
};

export const timestampToDate = timestamp => {
  const d = new Date(timestamp);

  const year = d.getFullYear();
  const month = formatMonth(d.getMonth());
  const date = d.getDate();

  const { hour, meridian } = formatHour(d.getHours());

  const minute = d.getMinutes();
  const second = d.getSeconds();

  const formattedMinute = countDigits(minute) < 2 ? `0${minute}` : minute;
  const formattedSeconds = countDigits(second) < 2 ? `0${second}` : second;

  return `${hour}:${formattedMinute}:${formattedSeconds} ${meridian} ${month} ${date}, ${year}`;
};

// counts emoji's as one char instead of 2
export const fancyCount = str =>
  Array.from(str.split(/[\ufe00-\ufe0f]/).join("")).length;

export const countEmojisInString = str => {
  const output = str.replace(
    /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g,
    ""
  );
  return fancyCount(str) - fancyCount(output);
};

export const sliceIncludingEmoji = (str, limit) => {
  const charArr = str.split("");
  return charArr
    .reduce(
      (vals, char, i) => {
        const { arr, skipNext } = vals;

        if (skipNext) return { arr, skipNext: false };

        if (charArr[i + 1] && countEmojisInString(char + charArr[i + 1])) {
          return { arr: [...arr, `${char}${charArr[i + 1]}`], skipNext: true };
        }
        return { arr: [...arr, char], skipNext: false };
      },
      { arr: [], skipNext: false }
    )
    .arr.slice(0, limit)
    .join("");
};
