import React, { useState, useContext } from "react";
import { animated, useTransition, useSpring } from "react-spring";
import styled from "styled-components";
import TimeIntervalDisplay from "../TimeIntervalDisplay/TimeIntervalDisplay";
import CharCountTextArea from "../elements/CharCountTextArea";
import { AppContext } from "../../context/AppContext";
import useIntervalOverview from "../../context/useIntervalOverview";

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 100px;
  height: calc(100% - 100px);
  flex-direction: column;
  overflow-y: scroll;
`;

const CompletedContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CompletedText = styled.div`
  color: limegreen;
  font-size: 45px;
`;

const ColumnContainer = styled.div`
  display: flex;
  width: 80%;
  margin: 50px 0;
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 210px;
  min-height: 210px;
  justify-content: ${props =>
    props.spaceBetween ? "space-between" : "flex-start"};
`;

const TextAreaLabel = styled.div`
  margin-bottom: 10px;
  font-size: 20px;
  width: 250px;
`;

const ConfirmNewTimeInterval = ({ scrollTop }) => {
  let activeInterval;
  // let clientFirstName;
  // let clientLastName;
  // let clientHourlyRate;
  let bucketName;
  let timeElapsed = 0;
  let onConfirm = () => {};

  const [state, setState] = useContext(AppContext);
  const { deleteFromQueue } = useIntervalOverview();

  const { intervalOverviewQueue } = state;

  const [note, setNote] = useState("");
  const [completed, setCompleted] = useState(false);

  const activeModal = state.slideoverModal === "confirmNewTimeInterval";

  const transitions = useTransition(activeModal, null, {
    from: {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: scrollTop,
      zIndex: 2,
      backgroundColor: "white",
      transform: "translate3d(100%, 0, 0)",
      boxShadow: "0px 0px 5px rgba(0,0,0,0.2)"
    },
    enter: { transform: "translate3d(0, 0, 0)" },
    leave: { transform: "translate3d(100%, 0, 0)" },
    onDestroyed: () => {
      setCompleted(false);
      if (intervalOverviewQueue.length) {
        setState(state => ({
          ...state,
          slideoverModal: "confirmNewTimeInterval"
        }));
      }
    }
  });

  const completedAnimation = useSpring({
    transform: completed ? "translate3d(0, 0, 0)" : "translate3d(0, 1000px, 0)"
  });

  if (intervalOverviewQueue.length) {
    activeInterval = intervalOverviewQueue[0];

    bucketName = activeInterval.timeBucket.name;

    timeElapsed = activeInterval.elapsed;

    onConfirm = () => {
      setCompleted(true);
      setTimeout(() => {
        deleteFromQueue(activeInterval.id);
        setState(state => ({ ...state, slideoverModal: "" }));
      }, 1000);
    };
  }

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div key={key} style={props}>
          {completed ? (
            <CompletedContainer>
              <animated.div style={completedAnimation}>
                <CompletedText>Completed!</CompletedText>
              </animated.div>
            </CompletedContainer>
          ) : (
            <Container>
              <div style={{ fontSize: 20 }}>New time for</div>
              <div style={{ fontSize: 50 }}>{`${bucketName}`}</div>

              <ColumnContainer>
                <Column spaceBetween>
                  <TimeIntervalDisplay timeElapsed={timeElapsed} />
                </Column>

                <Column>
                  <TextAreaLabel>Note:</TextAreaLabel>
                  <CharCountTextArea
                    maxChars={160}
                    onChange={text => setNote(text)}
                    value={note}
                    placeholder="short description of how time was used"
                    style={{ flex: 1 }}
                    textAreaStyle={{ height: 120 }}
                  />
                </Column>
              </ColumnContainer>

              <div style={{ marginBottom: 100 }}>
                <button onClick={onConfirm}>confirm</button>
                <button>edit</button>
              </div>
              {/* {activeInterval && (
                <TimeIntervalDetails timeInterval={activeInterval} />
              )} */}
            </Container>
          )}
        </animated.div>
      )
  );
};

export default ConfirmNewTimeInterval;
