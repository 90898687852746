import React, { useState } from "react";
import BucketForm from "./BucketForm";
import gql from "graphql-tag";
import AuthMutation from "../Apollo/AuthMutation";

const TIME_BUCKET = gql`
  mutation TimeBucket(
    $name: String!
    $description: String!
    $bucketType: String!
  ) {
    timeBucket(
      name: $name
      description: $description
      bucketType: $bucketType
    ) {
      timeBucket {
        id
      }
    }
  }
`;

const AddBucket = ({ history }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");

  const updateState = (value, type) => {
    if (type === "name") {
      setName(value);
      setError("");
    } else if (type === "description") {
      setDescription(value);
      setError("");
    }
  };

  return (
    <AuthMutation mutation={TIME_BUCKET}>
      {timeBucket => {
        const handleSubmit = async () => {
          const resp = await timeBucket({
            variables: {
              name,
              description,
              bucketType: "68b30da2-dd09-428e-a8f5-4df77cc25f34" //(base)
            }
          }).catch(() => setError("could not create bucket"));

          const { data } = resp;

          if (
            data &&
            data.timeBucket &&
            data.timeBucket.timeBucket &&
            data.timeBucket.timeBucket.id
          ) {
            history.push(`/bucket/${data.timeBucket.timeBucket.id}`);
          }
        };

        return (
          <BucketForm
            onOkay={handleSubmit}
            name={name}
            setName={value => updateState(value, "name")}
            description={description}
            setDescription={value => updateState(value, "description")}
            error={error}
          />
        );
      }}
    </AuthMutation>
  );
};

export default AddBucket;
