import React from "react";
import styled from "styled-components";

const AvatarWrapper = styled.div`
  background-color: #09f;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const BucketAvatar = ({ symbol, innerRef }) => (
  <AvatarWrapper ref={innerRef}>{symbol}</AvatarWrapper>
);

export default BucketAvatar;
