import React from "react";
import { Provider } from "react-redux";
import Dashboard from "./components/Dashboard";
import { BrowserRouter as Router, Route } from "react-router-dom";
import store from "./store";
import Login from "./components/Login/Login";
import { ApolloProvider } from "react-apollo";
import client from "./apolloConfig";
import PrivateRoute from "./components/Router/PrivateRoute";
import { AppProvider } from "./context/AppContext";

const App = () => {
  return (
    <AppProvider>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <Router>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/" component={Dashboard} />
          </Router>
        </Provider>
      </ApolloProvider>
    </AppProvider>
  );
};

export default App;
