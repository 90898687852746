import React from "react";
import gql from "graphql-tag";
import Timer from "react-compound-timer";
import { countDigits } from "../../utils/helpers";
import AuthMutation from "../Apollo/AuthMutation";
import useStopWatch from "../../hooks/useStopWatch";

const StopWatch = ({
  bucketId,
  startedTimer,
  onStop,
  setParentState,
  timeObjectMutation,
  timeObjectPauseMutation
}) => {
  const {
    timeStarted,
    timeFinished,
    initialTime,
    timerIsPaused,
    stopTime,
    resumeTime,
    pauseTime,
    startTime,
    stopWatchError
  } = useStopWatch({
    bucketId,
    startedTimer,
    onStop,
    setParentState,
    timeObjectMutation,
    timeObjectPauseMutation
  });

  return (
    <div style={{ display: "flex" }}>
      {timeStarted && !timeFinished ? (
        <Timer
          initialTime={initialTime}
          lastUnit="h"
          startImmediately={!timerIsPaused}
        >
          {({ pause: pauseTimer, resume: resumeTimer }) => (
            <div>
              <div>
                <button onClick={stopTime}>Stop</button>
                {timerIsPaused ? (
                  <button onClick={() => resumeTime(resumeTimer)}>
                    Resume
                  </button>
                ) : (
                  <button onClick={() => pauseTime(pauseTimer)}>Pause</button>
                )}
              </div>
              <Timer.Hours
                formatValue={val =>
                  countDigits(val) < 2 ? `0${val}` : `${val}`
                }
              />
              :
              <Timer.Minutes
                formatValue={val =>
                  countDigits(val) < 2 ? `0${val}` : `${val}`
                }
              />
              :
              <Timer.Seconds
                formatValue={val =>
                  countDigits(val) < 2 ? `0${val}` : `${val}`
                }
              />
            </div>
          )}
        </Timer>
      ) : (
        <div style={{ marginLeft: 8 }}>
          <button onClick={startTime}>Start</button>
        </div>
      )}
      {stopWatchError && (
        <div
          style={{
            color: "white",
            fontSize: 25,
            width: 30,
            height: 30,
            backgroundColor: "tomato",
            borderRadius: 15,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10
          }}
        >
          {stopWatchError}
        </div>
      )}
    </div>
  );
};

const TIME_OBJECT = gql`
  mutation TimeObject(
    $timeBucket: String
    $id: String
    $end: EpochDateTime
    $beginning: EpochDateTime
  ) {
    timeObject(
      timeBucket: $timeBucket
      id: $id
      end: $end
      beginning: $beginning
    ) {
      timeObject {
        id
        creator {
          id
        }
        timeBucket {
          id
          name
        }
        created
        beginning
        end
        elapsed
        elapsedWithPauses
        pauses {
          id
          beginning
          end
          elapsed
        }
      }
    }
  }
`;

const TIME_OBJECT_PAUSE = gql`
  mutation TimeObjectPause(
    $timeObject: String
    $end: EpochDateTime
    $beginning: EpochDateTime
  ) {
    timeObjectPause(timeObject: $timeObject, end: $end, beginning: $beginning) {
      timeObjectPause {
        timeObject {
          id
        }
        id
        beginning
        end
        elapsed
      }
    }
  }
`;

const StopWatchWrap = ({ ...props }) => (
  <AuthMutation mutation={TIME_OBJECT}>
    {timeObjectMutation => (
      <AuthMutation mutation={TIME_OBJECT_PAUSE}>
        {timeObjectPauseMutation => (
          <StopWatch
            timeObjectMutation={timeObjectMutation}
            timeObjectPauseMutation={timeObjectPauseMutation}
            {...props}
          />
        )}
      </AuthMutation>
    )}
  </AuthMutation>
);

export default StopWatchWrap;
