import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Mutation } from "react-apollo";
import { withRouter } from "react-router";
import gql from "graphql-tag";

const REFRESH_TOKEN = gql`
  mutation RefreshToken($token: String!) {
    refreshToken(refreshToken: $token) {
      token
      payload
      refreshToken
    }
  }
`;

const AuthHandler = ({ history, refreshToken }) => {
  const [state, setState] = useContext(AppContext);
  const [isCurrentlyRenewing, setIsCurrentlyRenewing] = useState(false);

  const { renewToken } = state;

  useEffect(() => {
    const handleRenew = async () => {
      const token = localStorage.getItem("timewyse_refresh_token");

      const logout = () => {
        localStorage.removeItem("timewyse_token");
        localStorage.removeItem("timewyse_refresh_token");
        setIsCurrentlyRenewing(false);
        history.push("/login");
      };

      const resp = await refreshToken({ variables: { token } }).catch(err => {
        console.warn(err);
        setIsCurrentlyRenewing(false);
      });

      if (
        resp &&
        resp.data &&
        resp.data.refreshToken &&
        resp.data.refreshToken.token &&
        resp.data.refreshToken.refreshToken
      ) {
        localStorage.setItem("timewyse_token", resp.data.refreshToken.token);
        localStorage.setItem(
          "timewyse_refresh_token",
          resp.data.refreshToken.refreshToken
        );

        setIsCurrentlyRenewing(false);
        setState(state => ({
          ...state,
          renewToken: false
        }));
      } else {
        logout();
      }
    };

    if (!isCurrentlyRenewing && renewToken) {
      setIsCurrentlyRenewing(true);
      handleRenew();
    }
  }, [
    renewToken,
    isCurrentlyRenewing,
    setIsCurrentlyRenewing,
    history,
    refreshToken,
    setState
  ]);

  return null;
};

const Wrap = props => (
  <Mutation mutation={REFRESH_TOKEN}>
    {refreshToken => <AuthHandler {...props} refreshToken={refreshToken} />}
  </Mutation>
);

export default withRouter(Wrap);
