import React from "react";
import styled from "styled-components";
import CharCountTextArea from "../elements/CharCountTextArea";

const BucketFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
  flex: 1;
`;

const InputField = styled.input`
  width: 350px;
  border: 1px solid rgba(0, 0, 0, 0);
  background-color: #eee;
  padding: 10px 15px;
  border-radius: 20px;
  margin: 10px;
  outline: none;
  font-size: 16px;
  box-sizing: border-box;
`;

const BucketForm = ({
  onOkay,
  editing,
  name,
  setName,
  description,
  setDescription,
  error
}) => (
  <BucketFormWrapper>
    <div style={{ fontSize: 30, marginBottom: 30 }}>
      {editing ? "Edit Time Bucket" : "Add New Time Bucket"}
    </div>
    <div style={{ fontSize: 16, color: "tomato", height: 20 }}>{error}</div>
    <InputField
      type="text"
      placeholder="Bucket Name"
      value={name}
      onChange={e => setName(e.target.value)}
    />
    <CharCountTextArea
      maxChars={160}
      value={description}
      onChange={setDescription}
      placeholder="Bucket Description"
      style={{ width: 350, marginBottom: 50 }}
      textAreaStyle={{
        width: "100%",
        boxSizing: "border-box",
        borderRadius: 20,
        padding: "10px 15px",
        backgroundColor: "#EEE",
        border: "1px solid rgba(0,0,0,0)"
      }}
    />
    <button onClick={onOkay}>Okay</button>
  </BucketFormWrapper>
);

export default BucketForm;
