import React from "react";
import gql from "graphql-tag";
import AuthQuery from "../Apollo/AuthQuery";
import { timestampToDate } from "../../utils/helpers";
import TimeIntervalDisplay from "../TimeIntervalDisplay/TimeIntervalDisplay";
import TimeObjectDetails from "../TimeObjectDetails";

const TIME_BUCKET_TIMERS = gql`
  query TimeBucket($id: String!) {
    timeBucket(id: $id) {
      id
      timers {
        id
        beginning
        end
        elapsedWithPauses
        pauses {
          id
          beginning
          end
          elapsed
        }
      }
    }
  }
`;

const BucketHistory = ({ bucketId }) => {
  return (
    <AuthQuery query={TIME_BUCKET_TIMERS} variables={{ id: bucketId }}>
      {({ data, loading, error }) => {
        if (loading) return <div>loading</div>;
        if (error || !data || !data.timeBucket || !data.timeBucket.timers)
          return <div>error</div>;

        return data.timeBucket.timers.map((timer, i) => {
          const { id, elapsedWithPauses: elapsed, beginning } = timer;
          return (
            <div
              style={{
                display: "flex",
                padding: 20,
                backgroundColor: i % 2 === 1 ? "white" : "#f6f6f6",
                justifyContent: "space-between"
              }}
              key={id}
            >
              <div style={{ flex: 1, paddingRight: 10 }}>
                <div>{timestampToDate(beginning)}</div>
                <div style={{ margin: "10px 0 0 10px" }}>
                  <TimeObjectDetails timeObject={timer} />
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  paddingLeft: 10,
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <TimeIntervalDisplay timeElapsed={elapsed} />
              </div>
            </div>
          );
        });
      }}
    </AuthQuery>
  );
};

export default BucketHistory;
