import React from "react";
import styled from "styled-components";
import gql from "graphql-tag";
import BucketCard from "./BucketCard";
import AuthQuery from "../Apollo/AuthQuery";

const ALL_BUCKETS = gql`
  {
    allBuckets {
      id
      name
      timers {
        id
        beginning
        end
        pauses {
          id
          beginning
          end
          elapsed
        }
      }
    }
  }
`;

const AddClientWrap = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
`;

const AddClientButton = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  font-size: 18px;
  outline: none;
  background-color: #5af;
  border-radius: 3px;
`;

const BucketList = ({ history }) => {
  return (
    <div
      style={{
        width: 300,
        backgroundColor: "#09F",
        overflowY: "scroll",
        borderRight: "1px solid #ccc"
      }}
    >
      <AuthQuery query={ALL_BUCKETS} pollInterval={2000}>
        {({ data, loading, error }) => {
          if (loading) {
            return <div>loading</div>;
          }

          if (
            error &&
            error.message !== "GraphQL error: Signature has expired"
          ) {
            return <div>error</div>;
          }

          return data && data.allBuckets && data.allBuckets.length ? (
            data.allBuckets.map(bucket => (
              <BucketCard bucket={bucket} key={bucket.id} />
            ))
          ) : (
            <AddClientWrap>
              <AddClientButton onClick={() => history.push("/addbucket")}>
                <span role="img" aria-label="ghost">
                  👻
                </span>{" "}
                such empty. add a bucket!
              </AddClientButton>
            </AddClientWrap>
          );
        }}
      </AuthQuery>
    </div>
  );
};

export default BucketList;
