import React from "react";
import { msToTime } from "../../utils/helpers";
import styled from "styled-components";

const TimeContainer = styled.div`
  font-size: 30px;
  color: #09f;
  width: 160px;
`;

const TimeIntervalDisplay = ({ timeElapsed }) => {
  const { seconds, minutes, hours } = msToTime(timeElapsed);
  return (
    <TimeContainer>
      <div>{hours} Hours</div>
      <div>{minutes} Minutes</div>
      <div>{seconds} Seconds</div>
    </TimeContainer>
  );
};

export default TimeIntervalDisplay;
