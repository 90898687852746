import { useState } from "react";
import { fancyCount, sliceIncludingEmoji } from "../utils/helpers";

function useCharLimiter(
  maxChars,
  value = "",
  onChange,
  errorMessage = "You have reached the maximum number of characters allowed."
) {
  const [error, setError] = useState("");

  const updateMessageAndClearErrors = val => {
    if (fancyCount(val) > maxChars) {
      onChange(sliceIncludingEmoji(val, maxChars));
      setError(errorMessage);
    } else {
      onChange(val);
      setError("");
    }
  };
  const charCount = fancyCount(value);
  return [updateMessageAndClearErrors, error, charCount];
}

export default useCharLimiter;
