import { useState } from "react";

const getMsDiff = (baseTime, diffTime) => {
  const baseTimeMs = baseTime % 1000;
  const diffTimeMs = diffTime % 1000;
  let msDiff;

  if (baseTimeMs <= diffTimeMs) {
    msDiff = diffTimeMs - baseTimeMs;
  } else {
    msDiff = diffTimeMs + 1000 - baseTimeMs;
  }

  return msDiff;
};

const useStopWatch = ({
  bucketId,
  startedTimer,
  onStop,
  setParentState,
  timeObjectMutation,
  timeObjectPauseMutation
}) => {
  const [timerIsPaused, setTimerIsPaused] = useState(!!startedTimer.isPaused);
  const [timeFinished, setTimeFinished] = useState(!!startedTimer.end);
  const [stopWatchError, setStopWatchError] = useState("");
  let timeStarted = !!startedTimer.id;
  let initialTime = 0;
  let timerId;
  let beginning;
  let isPaused;
  let pauses;
  let accumulativePausedTime;

  if (timeStarted) {
    timerId = startedTimer.id;
    beginning = startedTimer.beginning;
    isPaused = startedTimer.isPaused;
    pauses = startedTimer.pauses;

    accumulativePausedTime = pauses.reduce((totalElapsed, pause) => {
      return pause.elapsed + totalElapsed;
    }, 0);

    if (isPaused) {
      initialTime = isPaused - beginning - accumulativePausedTime;
    } else {
      initialTime = new Date().getTime() - beginning - accumulativePausedTime;
    }
  }

  const startTime = async () => {
    setParentState({ state: "loading", message: "" });

    const now = new Date().getTime();
    await timeObjectMutation({
      variables: { timeBucket: bucketId, beginning: now }
    }).catch(err => {
      console.warn(err);
      setStopWatchError("!");
    });
  };

  const stopTime = async () => {
    const end = new Date().getTime();
    const response = await timeObjectMutation({
      variables: { id: timerId, end }
    }).catch(err => {
      console.warn(err);
      setStopWatchError("!");
    });

    if (
      onStop &&
      response &&
      response.data &&
      response.data.timeObject &&
      response.data.timeObject.timeObject &&
      response.data.timeObject.timeObject.id &&
      response.data.timeObject.timeObject.beginning &&
      response.data.timeObject.timeObject.end &&
      response.data.timeObject.timeObject.elapsed &&
      response.data.timeObject.timeObject.timeBucket &&
      response.data.timeObject.timeObject.timeBucket.id &&
      response.data.timeObject.timeObject.timeBucket.name
    ) {
      const {
        id,
        beginning,
        end,
        elapsedWithPauses: elapsed,
        timeBucket
      } = response.data.timeObject.timeObject;
      const { id: timeBucketId, name: timeBucketName } = timeBucket;

      setTimeFinished(true);
      onStop({
        id,
        beginning,
        end,
        elapsed,
        timeBucket: { id: timeBucketId, name: timeBucketName }
      });
    } else {
      console.warn(
        "not all expected data was recieved from server to confirm timeObject",
        response
      );

      setStopWatchError("!");
    }
  };

  const pauseTime = async pauseTimer => {
    pauseTimer();
    setTimerIsPaused(true);

    const now = new Date().getTime();

    // this code makes sure that pauses happen on the exact second.
    // because of how the timer works, it will start the second over on resume
    const alteredNow = now - getMsDiff(beginning, now);

    await timeObjectPauseMutation({
      variables: { timeObject: timerId, beginning: alteredNow }
    }).catch(err => {
      console.warn(err);
      setStopWatchError("!");
    });
  };

  const resumeTime = async resumeTimer => {
    resumeTimer();
    setTimerIsPaused(false);

    const now = new Date().getTime();

    await timeObjectPauseMutation({
      variables: { timeObject: timerId, end: now }
    }).catch(err => {
      console.warn(err);
      setStopWatchError("!");
    });
  };

  return {
    timeStarted,
    initialTime,
    timerIsPaused,
    stopTime,
    resumeTime,
    pauseTime,
    startTime,
    stopWatchError,
    timeFinished
  };
};

export default useStopWatch;
