import React from "react";
// import { format } from "currency-formatter";
// import styled from "styled-components";
// import { QueryCrypto } from "shared";

// const TilesWrapper = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-around;
// `;

// const Tile = styled.div`
//   width: 200px;
//   background-color: ${props => (props.positiveChange ? "#66CD00" : "tomato")};
//   margin: 30px 10px;
//   border-radius: 10px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   padding: 20px 0;
// `;

// const SymbolCircle = styled.div`
//   height: 150px;
//   width: 150px;
//   border-radius: 75px;
//   background-color: #fff;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-size: 30px;
// `;

// const LowerTileWrap = styled.div`
//   padding-top: 10px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
// `;

const CryptoList = () => <div>yo</div>;

// <QueryCrypto>
//   {({ currencyData }) => (
//     <TilesWrapper>
//       {currencyData.map
//         ? currencyData.map(
//             ({ id, symbol, percent_change_24h, price_usd }) => (
//               <Tile key={id} positiveChange={percent_change_24h >= 0}>
//                 <SymbolCircle>{symbol}</SymbolCircle>
//                 <LowerTileWrap>
//                   <div>{format(price_usd, { code: "USD" })}</div>
//                   <div>{`${percent_change_24h}%`}</div>
//                 </LowerTileWrap>
//               </Tile>
//             )
//           )
//         : null}
//     </TilesWrapper>
//   )}
// </QueryCrypto>

export default CryptoList;
