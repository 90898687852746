import React from "react";
import useCharLimiter from "../../hooks/useCharLimiter";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextArea = styled.textarea`
  width: 300px;
  height: 160px;
  border: 1px solid #999;
  resize: none;
  font-size: 16px;
  padding: 10px;
  outline: none;
`;

// onChange={text => setNote(text)} value={note} placeholder="short description of how time was used"

const CharCountTextArea = ({
  maxChars,
  value,
  onChange,
  placeholder,
  style,
  textAreaStyle,
  charCountStyle
}) => {
  const [setText, limitError, charCount] = useCharLimiter(
    maxChars,
    value,
    onChange
  );

  return (
    <Container style={style}>
      <TextArea
        onChange={e => setText(e.target.value)}
        value={value}
        style={textAreaStyle}
        placeholder={placeholder}
      ></TextArea>

      <div style={charCountStyle}>{`${charCount}/${maxChars}`}</div>
      {limitError ? <div style={{ color: "red" }}>{limitError}</div> : null}
    </Container>
  );
};

export default CharCountTextArea;
