import React, { useRef, useState, useEffect } from "react";
import BucketAvatar from "../UI/BucketAvatar";
import useIntervalOverview from "../../context/useIntervalOverview";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { matchPath } from "react-router";
import StopWatch from "../StopWatch";

const CardWrapper = styled.div`
  padding: 20px 10px;
  background-color: ${props => (props.selected ? "#fff" : "#eee")};
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
`;

const isTimerPaused = pauses => {
  // will return time paused if true
  return pauses.reduce((pauseTime, pause) => {
    if (pauseTime) return pauseTime;

    if (pause.beginning && !pause.end) {
      return pause.beginning;
    }

    return pauseTime;
  }, 0);
};

const BucketCard = ({ bucket, history }) => {
  const { name, id, timers } = bucket;
  const [bucketCardState, setBucketCardState] = useState({
    state: "",
    message: ""
  });

  const startedTimer = timers.reduce((obj, timer) => {
    if (obj.id) return obj;
    if (timer.beginning && !timer.end) {
      const isPaused = isTimerPaused(timer.pauses);

      return {
        id: timer.id,
        beginning: timer.beginning,
        end: timer.end,
        isPaused,
        pauses: timer.pauses
      };
    }
    return obj;
  }, {});

  useEffect(() => {
    if (startedTimer.id && bucketCardState.state === "loading") {
      setBucketCardState({ state: "", message: "" });
    } else if (
      startedTimer.id &&
      startedTimer.isPaused &&
      bucketCardState.state === "loadingPause"
    ) {
      setBucketCardState({ state: "", message: "" });
    }
  }, [startedTimer, bucketCardState, setBucketCardState]);

  const symbol = name[0].toUpperCase();

  const containerRef = useRef();
  const avatarRef = useRef();
  const nameRef = useRef();

  const { addToQueue } = useIntervalOverview();

  const match = matchPath(history.location.pathname, {
    path: "/bucket/:bucketId",
    exact: true,
    strict: false
  });

  const confirmNewTimeIntval = data => {
    addToQueue(data);
  };

  const clickWrapper = e => {
    if (
      e.target === containerRef.current ||
      e.target === avatarRef.current ||
      e.target === nameRef.current
    ) {
      history.push(`/bucket/${id}`);
    }
  };

  let content;
  if (
    bucketCardState.state === "loading" ||
    bucketCardState.state === "loadingPause"
  ) {
    content = <div style={{ color: "limegreen" }}>loading</div>;
  } else if (bucketCardState.state === "error") {
    content = <div>{bucketCardState.message}</div>;
  } else {
    content = (
      <StopWatch
        bucketId={id}
        startedTimer={startedTimer}
        onStop={confirmNewTimeIntval}
        setParentState={setBucketCardState}
      />
    );
  }

  const selected =
    match &&
    match.params &&
    match.params.bucketId &&
    match.params.bucketId === id;

  return (
    <CardWrapper onClick={clickWrapper} ref={containerRef} selected={selected}>
      <BucketAvatar symbol={symbol} />
      <div style={{ marginLeft: 5 }} ref={nameRef}>
        {name}
      </div>
      {content}
    </CardWrapper>
  );
};

export default withRouter(BucketCard);
