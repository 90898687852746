import React, { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../../context/AppContext";
import { withApollo } from "react-apollo";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const User = ({ history, client }) => {
  const setState = useContext(AppContext)[1];

  const handleLogout = () => {
    localStorage.removeItem("timewyse_token");
    localStorage.removeItem("timewyse_refresh_token");
    client.resetStore();

    setState(state => ({ ...state, loggedIn: false }));
    history.push("/login");
  };

  return (
    <Container>
      <button onClick={handleLogout}>log out</button>
    </Container>
  );
};

export default withApollo(User);
