import React from "react";

export const owl = (
  <svg
    width="317px"
    height="247px"
    viewBox="0 0 317 247"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="SVG-Layer"
        transform="translate(0.000000, 53.000000)"
        fillRule="nonzero"
      >
        <path
          d="M0.306417228,4.32236534 C0.110203784,3.92344579 2.84217094e-14,3.47459263 2.84217094e-14,3 C2.84217094e-14,1.92582078 0.564557294,0.983500984 1.41314325,0.453569244 L140.823119,3 L176.176881,3 L315.568455,0.453569244 C316.416741,0.983899395 316.98106,1.92604786 316.98106,3 C316.98106,3.48101382 316.867854,3.93558696 316.666631,4.3385299 L275.808939,28.8432622 C282.240656,38.292819 286,49.7073886 286,62 L286,122 C286,154.5848 259.5848,181 227,181 L87,181 C54.4151998,181 28,154.5848 28,122 L28,62 C28,49.1526204 32.1063209,37.2643235 39.0780846,27.5759872 L0.306417228,4.32236534 Z"
          id="Combined-Shape"
          fill="#0080D5"
        ></path>
        <rect
          id="Rectangle"
          fill="#FFFFFF"
          x="40"
          y="20"
          width="109"
          height="145"
          rx="54.5"
        ></rect>
        <rect
          id="Rectangle"
          fill="#FFFFFF"
          x="160"
          y="20"
          width="109"
          height="145"
          rx="54.5"
        ></rect>
        <path
          d="M156.5,150 C163.983888,154.443546 169,162.607001 169,171.942033 C169,181.277065 163.983888,189.440521 156.5,193.884066 C149.016112,189.440521 144,181.277065 144,171.942033 C144,162.607001 149.016112,154.443546 156.5,150 Z"
          id="Combined-Shape"
          fill="#000000"
        ></path>
        <circle id="Oval" fill="#000000" cx="215" cy="107" r="43"></circle>
        <circle
          id="Oval"
          stroke="#FFFFFF"
          fill="#000000"
          cx="95"
          cy="107"
          r="43"
        ></circle>
      </g>
      <circle id="Oval" fill="#FFFFFF" cx="109.5" cy="150.5" r="16.5"></circle>
      <circle id="Oval" fill="#FFFFFF" cx="230.5" cy="150.5" r="16.5"></circle>
      <path
        d="M140.294625,12.7328178 C146.787727,19.3222894 150.794625,28.3683159 150.794625,38.3502036 C150.794625,48.3320914 146.787727,57.3781179 140.294625,63.9675895 C133.801523,57.3781179 129.794625,48.3320914 129.794625,38.3502036 C129.794625,28.3683159 133.801523,19.3222894 140.294625,12.7328178 Z"
        id="Combined-Shape"
        fillOpacity="0.832304414"
        fill="#0080D5"
        transform="translate(140.294625, 38.350204) rotate(-35.000000) translate(-140.294625, -38.350204) "
      ></path>
      <path
        d="M162,-0.0577140733 C169.418843,7.52999749 174,17.9650625 174,29.4830054 C174,41.0009482 169.418843,51.4360132 162,59.0237248 C154.581157,51.4360132 150,41.0009482 150,29.4830054 C150,17.9650625 154.581157,7.52999749 162,-0.0577140733 Z"
        id="Combined-Shape"
        fill="#0080D5"
        transform="translate(162.000000, 29.483005) rotate(13.000000) translate(-162.000000, -29.483005) "
      ></path>
    </g>
  </svg>
);

export const closeSvg = (
  <svg width="13px" height="13px" viewBox="0 0 13 13">
    <rect
      id="Rectangle"
      fill="#FFFFFF"
      transform="translate(6.363961, 6.363961) rotate(45.000000) translate(-6.363961, -6.363961) "
      x="-1.63603897"
      y="5.36396103"
      width="16"
      height="2"
      rx="1"
    />
    <rect
      id="Rectangle-2"
      fill="#FFFFFF"
      transform="translate(6.363961, 6.363961) rotate(45.000000) translate(-6.363961, -6.363961) "
      x="5.36396103"
      y="-1.63603897"
      width="2"
      height="16"
      rx="1"
    />
  </svg>
);

export const chevronSvg = (
  <svg viewBox="0 0 7 10">
    <path
      d="M3.26628591,5.72301993 L6.48392843,2.02154562 C6.84625983,1.60473103 7.47788245,1.56056368 7.89469704,1.92289507 C8.31151163,2.28522647 8.35567899,2.91684909 7.99334759,3.33366368 L4.05699342,7.86192116 C4.00308745,7.92393289 3.94322096,7.9776964 3.8789881,8.02310024 C3.4625308,8.35288425 2.85615933,8.29972945 2.50407003,7.89469704 L-1.43228414,3.36643956 C-1.79461554,2.94962497 -1.75044818,2.31800235 -1.33363359,1.95567095 C-0.916818997,1.59333955 -0.285196376,1.63750691 0.0771350207,2.0543215 L3.26628591,5.72301993 Z"
      transform="translate(3.280532, 4.958121) rotate(-90.000000) translate(-3.280532, -4.958121) "
    />
  </svg>
);
