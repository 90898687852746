import React, { useState } from "react";
import styled from "styled-components";

const SideBarButton = styled.button`
  color: #fff;
  width: 100%;
  height: 80px;
  font-size: 25px;
  background-color: ${props => (props.isHover ? "springgreen" : "transparent")};
  border: none;
  outline: none;
`;

const SidebarIcon = ({ children, onClick, style }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <SideBarButton
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      style={style}
      isHover={isHover}
    >
      {children}
    </SideBarButton>
  );
};

const Sidebar = ({ history }) => (
  <div
    style={{
      width: 80,
      backgroundColor: "#0547A6",
      display: "flex",
      flexDirection: "column"
    }}
  >
    <SidebarIcon onClick={() => history.push("/")}>
      <span
        style={{
          fontFamily: "'Quicksand', sans-serif",
          fontSize: 25,
          fontWeight: "bold"
        }}
      >
        TW
      </span>
    </SidebarIcon>
    <SidebarIcon onClick={() => history.push("/addbucket")}>+</SidebarIcon>
    <SidebarIcon onClick={() => history.push("/crypto")}>$</SidebarIcon>
    <SidebarIcon
      onClick={() => history.push("/user")}
      style={{ marginTop: "auto" }}
    >
      user
    </SidebarIcon>
  </div>
);

export default Sidebar;
