import { SET_NEW_CLIENT, EDIT_CLIENT } from "../actions/app";

const DEFAULT_STATE = {
  clients: [
    {
      firstName: "dallin",
      lastName: "christensen",
      hourlyRate: 90,
      id: "jxumwf6a"
    },
    {
      firstName: "Richard",
      lastName: "Silver",
      hourlyRate: 110,
      id: "jxumwf6b"
    },
    {
      firstName: "Hannes",
      lastName: "DeBeer",
      hourlyRate: 70,
      id: "jxumwf6c"
    },
    {
      firstName: "Faith",
      lastName: "Christensen",
      hourlyRate: 85,
      id: "jxumwf6d"
    },
    {
      firstName: "Puff",
      lastName: "Daddy?",
      hourlyRate: 210,
      id: "jxumwf6e"
    },
    {
      firstName: "Alan",
      lastName: "Rickman",
      hourlyRate: 50,
      id: "jxumwf6f"
    },
    {
      firstName: "Chuck",
      lastName: "Berry",
      hourlyRate: 92,
      id: "jxumwf6g"
    },
    {
      firstName: "Captain",
      lastName: "Planet",
      hourlyRate: 40,
      id: "jxumwf6h"
    },
    {
      firstName: "Roger",
      lastName: "Rabbig",
      hourlyRate: 220,
      id: "jxumwf6i"
    },
    {
      firstName: "Jason",
      lastName: "Bourne",
      hourlyRate: 71,
      id: "jxumwf6j"
    },
    {
      firstName: "Jack",
      lastName: "Black",
      hourlyRate: 64,
      id: "jxumwf6k"
    },
    {
      firstName: "Val",
      lastName: "Kilmer",
      hourlyRate: 70,
      id: "jxumwf6l"
    },
    {
      firstName: "Leo",
      lastName: "DeCap",
      hourlyRate: 80,
      id: "jxumwf6m"
    },
    {
      firstName: "Cody",
      lastName: "Willian",
      hourlyRate: 78,
      id: "jxumwf6n"
    },
    {
      firstName: "Bob",
      lastName: "Sagget",
      hourlyRate: 89,
      id: "jxumwf6o"
    }
  ]
};

export default function(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_NEW_CLIENT:
      return {
        ...state,
        clients: [...state.clients, action.newClient]
      };

    case EDIT_CLIENT:
      const newClients = [...state.clients];

      const i = newClients.findIndex(
        clients => clients.id === action.client.id
      );

      newClients[i] = action.client;

      return {
        ...state,
        clients: newClients
      };

    default:
      return state;
  }
}
