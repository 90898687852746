import React, { useContext } from "react";
import { Mutation } from "react-apollo";
import { withRouter } from "react-router";
import { AppContext } from "../../context/AppContext";

const AuthMutation = ({ children, history, ...props }) => {
  const [state, setState] = useContext(AppContext);
  const { renewToken } = state;

  return (
    <Mutation {...props}>
      {(mutation, ...rest) => {
        const runMutation = async (...args) => {
          const resp = await mutation(...args).catch(error => {
            if (
              error &&
              error.message &&
              error.message === "GraphQL error: Signature has expired"
            ) {
              if (!renewToken) {
                setState(state => ({
                  ...state,
                  renewToken: true
                }));
              }
            } else {
              throw error;
            }
          });

          return resp;
        };

        return children(runMutation, ...rest);
      }}
    </Mutation>
  );
};

export default withRouter(AuthMutation);
