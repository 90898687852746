import React, { useState } from "react";

const AppContext = React.createContext([{}, () => {}]);

const AppProvider = props => {
  const [state, setState] = useState({
    loggedIn: !!localStorage.getItem("timewyse_token"),
    slideoverModal: "",
    intervalOverviewQueue: [],
    renewToken: false
  });
  return (
    <AppContext.Provider value={[state, setState]}>
      {props.children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
