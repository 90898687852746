import React, { useEffect, useState, useRef, useContext } from "react";
import Sidebar from "../Sidebar";
import CryptoList from "../CryptoList";
import { Route } from "react-router-dom";
import BucketList from "../BucketList";
import ConfirmNewTimeInterval from "../slideoverModals/ConfirmNewTimeInterval";
import BucketView from "../BucketView/BucketView";
import { owl } from "../../svgs";
import styled from "styled-components";
import "../../fonts.css";
import User from "../User/User";
import gql from "graphql-tag";
import AuthQuery from "../Apollo/AuthQuery";
import { AppContext } from "../../context/AppContext";
import AddBucket from "../BucketForm/AddBucket";
import AuthHandler from "../Apollo/AuthHandler";

const ME = gql`
  {
    me {
      id
      password
      lastLogin
      isSuperuser
      firstName
      lastName
      email
      isStaff
    }
  }
`;

const RootContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const LogoAndNameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: center;
`;

const NameWrapper = styled.div`
  font-size: 90px;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const LogoWrapper = styled.div`
  & svg {
    width: 220px;
    height: 150px;
  }
`;

const Dashboard = ({ history }) => {
  const [mainScrollTop, setMainScrollTop] = useState(0);
  const mainDivRef = useRef();

  const [state, setState] = useContext(AppContext);

  const { intervalOverviewQueue } = state;

  const { slideoverModal } = state;

  // this checks if there are any time confirmations in queue
  useEffect(() => {
    if (intervalOverviewQueue.length) {
      setState(state => ({
        ...state,
        slideoverModal: "confirmNewTimeInterval"
      }));
    }
  }, [intervalOverviewQueue, setState]);

  //only fires when slideoverModal changes, if no more modal, reset mainScrollTop to what the page currently is.
  useEffect(() => {
    if (!slideoverModal) {
      setMainScrollTop(mainDivRef.current.scrollTop);
    }
  }, [slideoverModal]);

  return (
    <>
      <AuthHandler />
      <AuthQuery query={ME} pollInterval={10000}>
        {() => (
          <div style={{ display: "flex", height: "100vh" }}>
            <Sidebar history={history} />
            <BucketList history={history} />
            <div
              style={{
                flex: 1,
                overflowY: slideoverModal ? "hidden" : "scroll",
                position: "relative"
              }}
              onScroll={e => setMainScrollTop(e.target.scrollTop)}
              ref={mainDivRef}
            >
              <Route
                exact
                path="/"
                render={() => (
                  <RootContainer>
                    <LogoAndNameContainer>
                      <NameWrapper>TimeWyse</NameWrapper>
                      <LogoWrapper>{owl}</LogoWrapper>
                    </LogoAndNameContainer>
                  </RootContainer>
                )}
              />
              <Route path="/addbucket" component={AddBucket} />
              <Route path="/crypto" component={CryptoList} />
              <Route path="/bucket/:bucketId" component={BucketView} />
              <Route path="/user" component={User} />
              <ConfirmNewTimeInterval scrollTop={mainScrollTop} />
            </div>
          </div>
        )}
      </AuthQuery>
    </>
  );
};

export default Dashboard;
