import React, { useContext } from "react";
import { Query } from "react-apollo";
import { withRouter } from "react-router";
import { AppContext } from "../../context/AppContext";

const AuthQuery = ({ children, history, pollInterval, ...props }) => {
  const [state, setState] = useContext(AppContext);
  const { renewToken } = state;

  return (
    <Query {...props} pollInterval={pollInterval}>
      {({ error, ...rest }) => {
        const token = localStorage.getItem("timewyse_token");

        if (
          !token ||
          (error &&
            error.message &&
            error.message === "GraphQL error: Signature has expired")
        ) {
          if (!renewToken) {
            setState(state => ({
              ...state,
              renewToken: true
            }));
          }
        }

        return children({ error, ...rest });
      }}
    </Query>
  );
};

export default withRouter(AuthQuery);
