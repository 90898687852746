import React from "react";
import { useSpring, animated } from "react-spring";
import useMeasure from "../../hooks/useMeasure";

const Accordion = ({ children, open, customConfig, customStyles }) => {
  const [bind, measure] = useMeasure();

  const animation = useSpring({
    width: "100%",
    ...customStyles,
    height: open ? measure.height : 0,
    overflow: "hidden",
    config: { ...customConfig }
  });

  return (
    <animated.div style={animation}>
      <div {...bind}>{children}</div>
    </animated.div>
  );
};

export default Accordion;
