import React, { useState, useContext } from "react";
import styled from "styled-components";
import { owl } from "../../svgs";
import { Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { AppContext } from "../../context/AppContext";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  background-color: white;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  & svg {
    width: 150px;
  }
`;

const Input = styled.input`
  background-color: #eee;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 10px;
  font-size: 16px;
  border-radius: 18px;
  outline: none;
  margin: 5px 0;
  width: 320px;
  text-align: center;
  box-sizing: border-box;
  :focus {
    border: 1px solid #09f;
  }
`;

const SignInButton = styled.button`
  background-color: #09f;
  color: white;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 10px;
  font-size: 15px;
  border-radius: 18px;
  outline: none;
  margin: 5px 0;
  width: 320px;
  text-align: center;
  cursor: pointer;
  :hover {
    background-color: springgreen;
  }
  :focus {
    border: 1px solid springgreen;
  }
`;

const AltButton = styled.button`
  border: 1px solid rgba(0, 0, 0, 0);
  font-size: 14px;
  outline: none;
  margin: 10px 5px 5px 5px;
  cursor: pointer;
  :hover {
    color: #09f;
  }
  :focus {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
  }
`;

const Error = styled.div`
  height: 20px;
  color: tomato;
  font-weight: bold;
`;

const TOKEN_AUTH = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      refreshToken
    }
  }
`;

const Login = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const setState = useContext(AppContext)[1];

  const updateInput = ({ name, value }) => {
    if (name === "email") setEmail(value);
    if (name === "password") setPassword(value);
    setError("");
  };

  return (
    <Mutation mutation={TOKEN_AUTH}>
      {tokenAuth => {
        const handleLogin = async () => {
          const payload = await tokenAuth({
            variables: { email, password }
          }).catch(errResp => {
            if (
              errResp &&
              errResp.message &&
              errResp.message ===
                "GraphQL error: Please, enter valid credentials"
            ) {
              setError("invalid credentials");
            } else {
              setError("Error Occurred");
            }
          });

          if (
            payload &&
            payload.data &&
            payload.data.tokenAuth &&
            payload.data.tokenAuth.token &&
            payload.data.tokenAuth.refreshToken
          ) {
            localStorage.setItem(
              "timewyse_token",
              payload.data.tokenAuth.token
            );
            localStorage.setItem(
              "timewyse_refresh_token",
              payload.data.tokenAuth.refreshToken
            );
            setState(state => ({ ...state, loggedIn: true }));
            history.push("/");
            return null;
          } else {
            setError("Error Occurred");
          }
        };

        return (
          <Container>
            <FormContainer>
              <LogoWrapper>{owl}</LogoWrapper>
              <Error>{error}</Error>
              <Input
                placeholder="joe@email.com"
                value={email}
                onChange={e =>
                  updateInput({ name: "email", value: e.target.value })
                }
              />
              <Input
                placeholder="password"
                value={password}
                onChange={e =>
                  updateInput({ name: "password", value: e.target.value })
                }
                type="password"
              />
              <SignInButton onClick={handleLogin}>Sign In</SignInButton>
              <div>
                <AltButton>I'm new</AltButton>
                <AltButton>I forgot</AltButton>
              </div>
            </FormContainer>
          </Container>
        );
      }}
    </Mutation>
  );
};

export default Login;
