import React, { useState } from "react";
import BucketForm from "./BucketForm";
import gql from "graphql-tag";
import AuthMutation from "../Apollo/AuthMutation";

const EDIT_TIME_BUCKET = gql`
  mutation TimeBucket(
    $id: String!
    $name: String!
    $description: String!
    $bucketType: String!
  ) {
    timeBucket(
      id: $id
      name: $name
      description: $description
      bucketType: $bucketType
    ) {
      timeBucket {
        id
      }
    }
  }
`;

const EditBucket = ({
  name: propsName,
  description: propsDescription,
  id,
  onSubmit
}) => {
  const [name, setName] = useState(propsName);
  const [description, setDescription] = useState(propsDescription);
  const [error, setError] = useState("");

  return (
    <AuthMutation mutation={EDIT_TIME_BUCKET}>
      {editTimeBucket => {
        const submitEdit = async () => {
          const resp = await editTimeBucket({
            variables: {
              id,
              name,
              description,
              bucketType: "68b30da2-dd09-428e-a8f5-4df77cc25f34"
            }
          }).catch(() => setError("Error Occurred"));

          if (resp && onSubmit) onSubmit();
        };

        return (
          <BucketForm
            onOkay={submitEdit}
            editing
            name={name}
            setName={setName}
            description={description}
            setDescription={setDescription}
            error={error}
          />
        );
      }}
    </AuthMutation>
  );
};

export default EditBucket;
