export const SET_NEW_CLIENT = "SET_NEW_CLIENT";
export const EDIT_CLIENT = "EDIT_CLIENT";

export const setNewClient = newClient => ({
  type: SET_NEW_CLIENT,
  newClient
});

export const editClient = client => ({
  type: EDIT_CLIENT,
  client
});
