import React, { useState } from "react";
import { timestampToDate, msToTime } from "../../utils/helpers";
import Accordion from "../Animations/Accordion";
import { useSpring, animated } from "react-spring";
import { chevronSvg } from "../../svgs";
import styled from "styled-components";

const CollapseExpandButton = styled.button`
  padding: 0;
  outline: none;
  cursor: pointer;
  width: 18px;
  border: none;
  background-color: transparent;
`;

const RecipientsCarrotWrapper = styled.span`
  & svg {
    width: 10px;
  }
  & path {
    fill: #555459;
    stoke-width: 2.5px;
  }
`;

const TimeObjectDetails = ({ timeObject }) => {
  const { beginning, end, pauses, elapsedWithPauses: elapsed } = timeObject;
  const [open, setOpen] = useState(false);

  const animation = useSpring({
    transform: open ? "rotate(90deg)" : "rotate(0deg)"
  });

  const { hours, minutes, seconds } = msToTime(elapsed);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <animated.div style={animation}>
          <CollapseExpandButton onClick={() => setOpen(!open)}>
            <RecipientsCarrotWrapper>{chevronSvg}</RecipientsCarrotWrapper>
          </CollapseExpandButton>
        </animated.div>
        <div>details</div>
      </div>
      <Accordion open={open}>
        <div style={{ backgroundColor: "#eee", padding: 10 }}>
          <div>start: {timestampToDate(beginning)}</div>
          <div>end: {timestampToDate(end)}</div>
          {pauses.length ? (
            <div style={{ marginTop: 10 }}>
              <div>{pauses.length > 1 ? "pauses:" : "pause:"}</div>
              <div>
                {pauses.map(pause => {
                  const {
                    hours: pHours,
                    minutes: pMinutes,
                    seconds: pSeconds
                  } = msToTime(pause.elapsed);

                  return (
                    <div key={pause.id} style={{ margin: 8 }}>
                      <div>pause start: {timestampToDate(pause.beginning)}</div>
                      <div>pause end: {timestampToDate(pause.end)}</div>
                      <div>
                        pause duration: {`${pHours}:${pMinutes}:${pSeconds}`}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
          <div>total elapsed time: {`${hours}:${minutes}:${seconds}`}</div>
        </div>
      </Accordion>
    </div>
  );
};

export default TimeObjectDetails;
